<template>
  <li class="logout">
    <a @click="handleLogout">Déconnexion</a>
  </li>
  <li>
    <a @click.prevent="downloadApiFile('pdf_glossaire', 'glossaire')"> Glossaire </a>
  </li>
  <li>
    <a @click.prevent="downloadApiFile('pdf_documentation', 'methodologie-silogues')">
      Méthodologie Silogues
    </a>
  </li>

  <li class="deactivated" v-if="isGranted('ROLE_ADMIN')">
    <a href="">Gestion des équipes</a>
  </li>
  <li>
    <router-link :to="{ name: 'account' }" v-if="!isGranted('ROLE_DEMO')">
      Administration du compte
    </router-link>
  </li>
</template>

<script setup>
import { mapActions } from '@/store/mappers';
import { downloadApiFile } from '@/api/commons';
import { isGranted } from '@/composables/security';

const { logout } = mapActions('security');
const handleLogout = () => {
  logout();
  window.location.href = '/';
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';

li {
  padding: 1rem 0;
  border-top: 1px solid $white;

  a {
    color: $white;
    font-size: 1rem;
    line-height: 1.375rem;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }

  .icon-lock {
    margin-right: 0.5rem;

    &:before {
      color: $white;
    }
  }
}
</style>
